/* Hide scrollbar for Chrome, Safari and Opera */
.custom-scroll {
    overflow: hidden; /* Hide scrollbar */
}

.custom-scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

/* Optional: If you want to keep scroll functionality */
.custom-scroll {
    overflow-y: scroll; /* Allow vertical scrolling */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
}
